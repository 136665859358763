<template>
  <form action="#" class="form" data-gw-tab="media" data-gw-tab-grp="form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Choose</div>
      </div>
      <select
        class="tt-uppercase"
        ref="type"
        v-model="type"
        data-track-stab
        data-select-media-action
      >
        <option value="add">Add</option>
        <option value="watch">Watch</option>
      </select>
    </div>
    <div class="field field_grp field_mb" data-gw-tab-grp="media">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Choose</div>
      </div>
      <select class="tt-uppercase" ref="method" v-model="method">
        <option value="stream">Live Stream / Video</option>
        <option value="gallery">Gallery</option>
      </select>
    </div>
    <div class="form__btn-box">
      <a :href="url" target="_blank" class="button" data-btn-media>
        <span class="button__content">Continue</span>
      </a>
    </div>
  </form>
</template>

<script>
export default {
  name: "MediaForm",
  computed: {
    url() {
      const url = [
        {
          add: {
            stream: "https://forum.la2dream.com/video/post/",
            gallery: "https://forum.la2dream.com/gallery/submit/",
          },
          watch: {
            stream: "https://forum.la2dream.com/video/",
            gallery: "https://forum.la2dream.com/gallery/",
          },
        },
      ];

      return url[0][this.type][this.method];
    },
  },
  data() {
    return {
      type: "add",
      method: "stream",
    };
  },
};
</script>

<style scoped></style>
